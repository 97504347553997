/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, {Fragment} from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import {Styled, css, jsx} from "theme-ui"
import bioImg from "../../images/bio.jpg"

// Components
import Layout from "gatsby-theme-blog/src/components/layout"
import SEO from "gatsby-theme-blog/src/components/seo"
import PostFooter from "gatsby-theme-blog/src/components/post-footer"
import ExternalLink from "../../components/externalLink";

const Me = () => {
  const data = useStaticQuery(meQuery)
  const {
    site: {
      siteMetadata: { author },
    },
    bio,
  } = data

    console.log('data', data)

  const meHeader = `Piyush Dankhra`

  return (
      <Layout title={'About Me'}>
        <SEO
            title={meHeader}
        />
        <main>
          <header>
          </header>
            <section>
                <img
                    src={bioImg}
                    alt={author}
                    style={{
                        objectFit: "cover",
                        marginRight: "8px",
                        marginBottom: "0",
                        width: "992px",
                        minWidth: "992px",
                    }}
                />
                <Styled.div>
                    <Fragment>
                        <p>I'm Piyush Dankhra,</p>
                        <p>A Magento (Adobe Commerce) professional with over 12 years of experience and a proud member of the Adobe Commerce Front-End Developer and Business Practitioner Exam Creation Team.</p>
                        <p>Over the years, I have gained experience in setting up B2B features, hosting, and developing extensions, themes, PWA, and Hyvä.</p>
                        <p>I have worked on a variety of projects across industries such as fashion, jewelry, auto parts, cosmetics, and manufacturing. I am also skilled in managing extensions listed on the Magento Marketplace.</p>
                        <p>I hold the following Adobe Commerce certifications:</p>
                        <ul>
                            <li><ExternalLink href="https://www.credly.com/badges/7a4c853b-2190-42fd-b7c5-f77a1e4e18c6"
                                              title={"Adobe Commerce Architect"}>Adobe Certified Master – Adobe Commerce Architect</ExternalLink></li>
                            <li><ExternalLink href="https://www.credly.com/badges/7a4c853b-2190-42fd-b7c5-f77a1e4e18c6"
                                              title={"Adobe Commerce Developer"}>Adobe Certified Expert – Adobe Commerce Developer</ExternalLink></li>
                            <li><ExternalLink href="https://www.credly.com/badges/7a4c853b-2190-42fd-b7c5-f77a1e4e18c6"
                                              title={"Adobe Commerce Front-End Developer"}>Adobe Certified Expert – Adobe Commerce Front-End Developer</ExternalLink></li>
                            <li><ExternalLink href="https://www.credly.com/badges/7a4c853b-2190-42fd-b7c5-f77a1e4e18c6"
                                              title={"Adobe Commerce Business Practitioner"}>Adobe Certified Expert – Adobe Commerce Business Practitioner</ExternalLink></li>
                            <li><ExternalLink href="https://www.credly.com/badges/7a4c853b-2190-42fd-b7c5-f77a1e4e18c6"
                                              title={"Adobe Commerce Front-End Developer Expert"}>Adobe Subject Matter Expert – Adobe Commerce Front-End Developer Expert</ExternalLink></li>
                            <li><ExternalLink href="https://www.credly.com/badges/7a4c853b-2190-42fd-b7c5-f77a1e4e18c6"
                                              title={"Adobe Commerce Business Practitioner"}>Adobe Subject Matter Expert – Adobe Commerce Business Practitioner</ExternalLink></li>
                        </ul>
                        <p>I was a speaker at the Meet Magento India 2023 (MM23IN) event, where I gave a presentation on setting up local dev environments using the Rewardenv CLI tool.</p>
                        <p>Connect with me on <ExternalLink
                            href="https://www.linkedin.com/in/piyussh">LinkedIn</ExternalLink>.</p>
                    </Fragment>
                </Styled.div>
            </section>
        </main>
          <PostFooter/>
      </Layout>
  )
}

const meQuery = graphql`
    query meQuery {
        site
        {
            siteMetadata
            {
                author
            }
        }
        bio: file(absolutePath: { regex: "/bio.(jpeg|jpg|gif|png)/" }) {
            childImageSharp {
                fixed(width: 992) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`

export default Me
